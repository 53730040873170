#projects-sub {
  .container {
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
  }
  .project-box {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @extend .container;
    @media (min-width: 992px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 30px;
    }
    .project {
      border-radius: var(--border-radius);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      position: relative;
      overflow: hidden;
      height: 200px;
      @media (min-width: 992px) {
        height: 300px;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--color-secondary);
        background: linear-gradient(
          0deg,
          var(--color-secondary) 0%,
          transparent 100%
        );
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      > span {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        padding: 10px 15px;
        color: #fff;
        transform: translateY(100%);
        transition: transform 0.2s ease;
        > h3 {
          margin: 0;
          font-size: 1.2rem;
          color: #fff;
        }
        > p {
          margin: 0;
          font-size: 0.875rem;
        }
      }
      &:hover {
        &::after {
          opacity: 1;
        }
        > span {
          transform: translateY(0%);
        }
      }
    }
  }
}

/* Filtering table */
// Hide all rows by default
.table-filter {
  tbody tr {
    display: none;
  }
}

// Generic styling radiobutton
// Hides the radio button
input[type="radio"].tablefilter {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  z-index: -1;
}
//Use the label as btn
label.filterBtn {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  margin: 0 0 4px 0;
  transition: 0.3s;
  padding: 4px 6px;
  font-size: 12px;
  border-radius: var(--border-radius);
}

input[type="radio"].tablefilter:checked + label {
  background: var(--color-primary);
  color: #fff;
  transition: 0.3s;
}
input[type="radio"].tablefilter:not(checked) + label {
  background: var(--bg-secondary-color);
  color: var(--color-headline);
  transition: 0.3s;
}

input[type="radio"].tablefilter[data-filter="Cat1"] {
  &:checked {
    ~ .table-wrapper .table-filter tbody tr[data-filter="Cat1"] {
      display: table-row;
    }
  }
}
input[type="radio"].tablefilter[data-filter="Cat2"] {
  &:checked {
    ~ .table-wrapper .table-filter tbody tr[data-filter="Cat2"] {
      display: table-row;
    }
  }
}
input[type="radio"].tablefilter[data-filter="Cat3"] {
  &:checked {
    ~ .table-wrapper .table-filter tbody tr[data-filter="Cat3"] {
      display: table-row;
    }
  }
}
input[type="radio"].tablefilter[data-filter="Cat4"] {
  &:checked {
    ~ .table-wrapper .table-filter tbody tr[data-filter="Cat4"] {
      display: table-row;
    }
  }
}
input[type="radio"].tablefilter[data-filter="Cat5"] {
  &:checked {
    ~ .table-wrapper .table-filter tbody tr[data-filter="Cat5"] {
      display: table-row;
    }
  }
}
input[type="radio"].tablefilter[data-filter="All"] {
  &:checked {
    ~ .table-wrapper .table-filter tbody tr {
      display: table-row;
    }
  }
}

// responsive table stuff
.ref-table {
  margin-top: 40px;
  .table-wrapper {
    margin-top: 30px;
    max-height: 500px;
    overflow-y: scroll;
    table {
      font-size: 16px;
      thead {
        tr {
          th {
            color: var(--color-headline);
          }
        }
      }
      tbody,
      thead {
        tr {
          td,
          th {
            padding: 0.7rem 0.4rem;
            &:nth-child(1) {
              width: 40%;
            }
            &:nth-child(2) {
              width: 30%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 10%;
              text-align: center;
              > a {
                background: none !important;
                font-size: 14px;
                display: block;
              }
            }
          }
          &:nth-child(odd) td {
            background: #fafafb;
          }
        }
      }
    }
  }
}
