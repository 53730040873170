#service-sub {
  .boxes {
    margin-bottom: 30px;
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 15px;
    }
    @media (min-width: 992px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 30px;
      margin-bottom: 50px;
    }
    .box {
      position: relative;
      overflow: hidden;
    }
  }
}
