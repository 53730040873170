#hero {
  position: relative;
  min-height: auto;
  overflow: hidden;
  margin: 100px 0px 0px 0px;
  display: flex;
  align-items: center;
  background: rgba(98, 187, 230, 1);
  background: linear-gradient(
    0deg,
    rgba(98, 187, 230, 1) 0%,
    rgba(72, 178, 230, 1) 100%
  );
  @media (min-width: 992px) {
    min-height: 640px;
    margin: 120px 0px 30px 0px;
  }
  .container {
    display: grid;
    align-items: center;
    grid-gap: 0;
    grid-template-columns: 1fr;
    padding: 3rem 0;
    @media (min-width: 768px) {
      background: none;
      padding: 3rem 0;
      grid-template-columns: 65% auto;
      grid-gap: 30px;
    }
    @media (min-width: 992px) {
      background: none;
      padding: 4rem 0;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 80px;
    }
    .text-box {
      position: relative;
      z-index: 10;
      color: #fff;
      h1 {
        position: relative;
        margin: 0;
        color: #fff;
        z-index: 1;
        @media (min-width: 992px) {
          line-height: 70px;
        }
        span {
          position: relative;
          z-index: 1;
          &::after {
            content: "";
            position: absolute;
            z-index: -1;
            left: -10px;
            right: -10px;
            bottom: -2px;
            height: 100%;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 290 12' stroke='%233454D0' fill='none' stroke-width= '4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9.50005C83.0638 0.750526 150 -4.76837e-07 285.5 9.50003'%3E%3C/path%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;
            opacity: 0.6;
          }
        }
      }
      p {
        margin: 32px 0 40px;
        @media (min-width: 440px) {
          padding-right: 40%;
        }
        @media (min-width: 768px) {
          padding: 0;
        }
      }
    }
  }
  .image {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 70%;
    height: 100%;
    margin-right: -25%;
    background: url(../images/hero.png);
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom-right-radius: var(--border-radius);
    @media (min-width: 992px) {
      width: 865px;
      max-width: 50%;
      margin: 0;
    }
  }
}

#hero-sub {
  position: relative;
  height: auto;
  overflow: hidden;
  margin: 100px 0px 0px 0px;
  background: rgba(98, 187, 230, 1);
  background: linear-gradient(
    0deg,
    rgba(98, 187, 230, 1) 0%,
    rgba(72, 178, 230, 1) 100%
  );
  @media (min-width: 992px) {
    height: 300px;
    margin: 120px 0px 30px 0px;
  }
  .container {
    color: #fff;
    padding: 3rem 0;
    @media (min-width: 992px) {
      background: none;
      padding: 4rem 0;
    }
    h1 {
      color: #fff;
      display: inline-block;
      position: relative;
      z-index: 10;
      margin: 0;
      line-height: 1.3;
      padding-right: 25%;
      @media (min-width: 992px) {
        padding: 0;
      }
    }
    p {
      position: relative;
      z-index: 10;
      margin: 0;
      padding-right: 25%;
      @media (min-width: 992px) {
        padding: 0;
      }
      &.lead {
        margin: 0 0 20px 0;
      }
      > a {
        color: #fff;
      }
    }
  }
  .image {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 70%;
    height: 100%;
    margin-right: -25%;
    background: url(../images/hero.png);
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom-right-radius: var(--border-radius);
    @media (min-width: 992px) {
      width: 865px;
      max-width: 50%;
      margin: 0;
    }
  }
}
