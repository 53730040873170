#header {
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  height: 100px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  @media (min-width: 992px) {
    padding: 0 30px;
    height: 120px;
  }
  .container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    .logo {
      display: block;
      height: 60px;
      > img {
        height: 60px;
      }
    }
    nav {
      @media (max-width: 767px) {
        position: absolute;
        z-index: 11;
        top: 80px;
        right: 0px;
        background-color: var(--color-primary);
        padding: 20px;
        min-width: 180px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
        border-radius: var(--border-radius);
        opacity: var(--menu-opacity, 0);
        pointer-events: var(--menu-pointer, none);
        transform: translateY(var(--menu-y, -10px));
        transition: opacity 0.25s, transform 0.3s;
        &::after {
          content: "";
          width: 14px;
          height: 14px;
          position: absolute;
          right: 8px;
          top: -4px;
          transform: rotate(45deg);
          background-color: var(--color-primary);
          border-radius: var(--border-radius) 0 0 0;
        }
      }
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        @media (max-width: 767px) {
          flex-direction: column;
        }
        &.nav-ueberuns {
          > li:nth-child(1) {
            a {
              color: var(--color-secondary);
              @media (max-width: 767px) {
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
        &.nav-leistungen {
          > li:nth-child(2) {
            a {
              color: var(--color-secondary);
              @media (max-width: 767px) {
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
        &.nav-referenzen {
          > li:nth-child(3) {
            a {
              color: var(--color-secondary);
              @media (max-width: 767px) {
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
        &.nav-kontakt {
          > li:nth-child(4) {
            a {
              color: var(--color-secondary);
              @media (max-width: 767px) {
                color: #fff;
                opacity: 1;
              }
            }
          }
        }

        > li {
          display: inline-flex;
          a {
            padding: 8px 12px;
            font-size: 16px;
            font-weight: 500;
            color: var(--color-text);
            text-decoration: none;
            @media (max-width: 767px) {
              padding: 8px 0;
              color: #fff;
              opacity: 0.75;
            }
          }
        }
      }
      &.open {
        --menu-opacity: 1;
        --menu-y: 0px;
        --menu-pointer: auto;
      }
    }
    .btn {
      display: none;
      position: relative;
      @media (min-width: 992px) {
        display: inline-flex;
      }
      > span {
        position: absolute;
        right: -9px;
        top: -9px;
        width: 28px;
        height: 28px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        transform: scale(0);
        z-index: 1;
        animation: notification 0.3s cubic-bezier(0.48, 1.97, 0.5, 0.63)
          forwards 1s;
        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          display: block;
          background: #fff;
          border-radius: 50%;
          opacity: 0.5;
          animation: bling 2s cubic-bezier(0.32, 0.64, 0.58, 1.08) 1.4s infinite
            forwards;
          z-index: -1;
        }
        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          display: block;
          border: 3px solid #fff;
          background: var(--color-secondary);
          border-radius: 50%;
          z-index: -1;
        }
      }
      @keyframes bling {
        50% {
          transform: scale(1);
          opacity: 1;
        }
        100% {
          transform: scale(2);
          opacity: 0;
        }
      }
      @keyframes notification {
        to {
          transform: scale(1);
        }
      }
    }
    .burger {
      -webkit-appearance: none;
      width: 32px;
      height: 32px;
      position: relative;
      outline: none;
      border: none;
      background: none;
      padding: 0;
      margin: 0 0 0 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: 768px) {
        display: none;
      }
      &:active {
        transform: scale(1);
      }
      > span {
        background-color: var(--b, var(--color-text));
        display: block;
        position: relative;
        width: 32px;
        height: 4px;
        border-radius: 2px;
        transform: scale(1);
        transition: background-color 0.25s;
        &::before,
        &::after {
          content: "";
          display: block;
          height: 4px;
          width: 32px;
          border-radius: 2px;
          position: absolute;
          background-color: var(--color-text);
          left: 0;
          transform: translateY(var(--y, var(--before-y, 0)))
            rotate(var(--r, var(--before-r, 0deg))) translateZ(0);
          transition: transform 0.25s;
        }
        &::before {
          top: -10px;
        }
        &::after {
          top: 10px;
          --y: var(--after-y, 0);
          --r: var(--after-r, 0);
        }
      }
      &.active {
        --b: transparent;
        --before-y: 10px;
        --before-r: 45deg;
        --after-y: -10px;
        --after-r: -45deg;
      }
    }
  }
}
