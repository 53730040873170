#career-sub {
  .container {
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
  }
  .image-box {
    margin-top: 50px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media (min-width: 992px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 30px;
    }
    .image {
      border-radius: var(--border-radius);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      position: relative;
      overflow: hidden;
      height: 200px;
      @media (min-width: 992px) {
        height: 230px;
      }
      &.image-01 {
        @media (max-width: 991px) {
          grid-column: 1/-1;
        }
      }
      &.image-02 {
        border: 1px solid #eee;
      }
    }
  }
}
