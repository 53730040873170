.accordion {
  border: 1px solid var(--bg-secondary-color);
  border-radius: var(--border-radius);
  counter-reset: number;
  .item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--bg-secondary-color);
    }
    button {
      -webkit-appearance: none;
      outline: none;
      display: block;
      position: relative;
      text-align: left;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      width: 100%;
      color: inherit;
      font-family: inherit;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 40px 10px 16px;
      height: 100%;
      min-height: 56px;
      @media (min-width: 992px) {
        padding: 0 52px 0 24px;
        min-height: 80px;
      }
      &:active {
        transform: scale(1);
      }
      h2 {
        margin: 0;
      }
      .list {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        margin-right: 20px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: var(--color-primary);
        @media (min-width: 992px) {
          width: 32px;
          height: 32px;
          margin-right: 40px;
        }
        > svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .toggle {
        width: var(--toggle-size, 24px);
        height: var(--toggle-size, 24px);
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: var(--toggle-size-half, -12px);
        opacity: var(--toggle-opacity, 0.75);
        transition: opacity 0.3s ease;
        @media (min-width: 992px) {
          right: 20px;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 2px;
          height: 8px;
          border-radius: 2px;
          background: var(--color-text);
          top: 50%;
          margin-top: -5px;
          left: var(--l, 9px);
          transform: rotate(var(--r, var(--toggle-rotate-before, -45deg)));
          transition: transform 0.25s;
        }
        &:after {
          --l: 14px;
          --r: var(--toggle-rotate-after, 45deg);
        }
      }
    }
    .content {
      &:not(.open) {
        display: none;
      }
      > div {
        padding: 0 16px 16px 60px;
        @media (min-width: 992px) {
          padding: 0 52px 32px 96px;
        }
      }
    }
    &.active {
      --toggle-rotate-before: -135deg;
      --toggle-rotate-after: 135deg;
    }
  }
}
