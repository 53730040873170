#about-us-start {
  position: relative;
  margin: 4rem 0;
  @media (min-width: 992px) {
    margin: 6rem 0;
  }
  .container {
    display: grid;
    align-items: center;
    grid-gap: 60px;
    grid-template-columns: 1fr;
    @media (min-width: 992px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 80px;
    }
    .text-box {
      .btn {
        margin-top: 30px;
      }
    }
    .image-box {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      @media (min-width: 992px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
      .image {
        width: 100%;
        height: 200px;
        border-radius: var(--border-radius);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        position: relative;
        overflow: hidden;
        &.image-01 {
          grid-row: 1;
          grid-column: 1/-1;
          height: 250px;
          background-position: 50% 20%;
          @media (min-width: 992px) {
            grid-column: 1/-2;
            height: 170px;
            margin-left: 30px;
          }
          @media (min-width: 1260px) {
            height: 270px;
          }
        }
        &.image-02 {
          grid-row: 2;
          grid-column: 1/2;
          height: 200px;
          @media (min-width: 992px) {
            grid-column: 1/4;
            height: 200px;
          }
          @media (min-width: 1260px) {
            height: 300px;
          }
        }
        &.image-03 {
          grid-row: 2;
          grid-column: 2/-1;
          height: 200px;
          @media (min-width: 992px) {
            grid-column: 4/-1;
            height: 230px;
          }
          @media (min-width: 1260px) {
            height: 330px;
          }
        }
      }
    }
  }
}
#projects-start {
  position: relative;
  margin: 4rem 0;
  @media (min-width: 992px) {
    margin: 6rem 0;
  }
  .container {
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
    .text-box {
      @media (min-width: 992px) {
        text-align: center;
        max-width: 720px;
        margin: 0 auto;
      }
    }
  }
  .project-box {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @extend .container;
    @media (min-width: 992px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
      grid-gap: 30px;
      height: 520px;
    }
    @media (min-width: 1260px) {
      max-width: 1670px;
      height: 720px;
      padding: 0 30px;
    }
    .project {
      border-radius: var(--border-radius);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      position: relative;
      overflow: hidden;
      height: 200px;
      @media (min-width: 992px) {
        height: auto;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--color-secondary);
        background: linear-gradient(
          0deg,
          var(--color-secondary) 0%,
          transparent 100%
        );
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      &.project-01 {
        @media (min-width: 992px) {
          margin: 30px 0 0 30px;
          grid-row: 1;
          grid-column: 1/3;
        }
      }
      &.project-02 {
        @media (min-width: 992px) {
          grid-row: 1/2;
          grid-column: 3/4;
        }
      }
      &.project-03 {
        @media (min-width: 992px) {
          margin: 30px 0 0 0;
          grid-row: 1;
          grid-column: 4/-1;
        }
      }
      &.project-04 {
        @media (min-width: 992px) {
          grid-row: 2;
          grid-column: 1/2;
          margin-bottom: 30px;
        }
      }
      &.project-05 {
        @media (min-width: 992px) {
          grid-row: 2;
          grid-column: 2/4;
        }
      }
      &.project-06 {
        @media (min-width: 992px) {
          grid-row: 2;
          grid-column: 4/-1;
          margin: 0 30px 0 0;
        }
      }
      > span {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        padding: 10px 15px;
        color: #fff;
        transform: translateY(100%);
        transition: transform 0.2s ease;
        > h3 {
          margin: 0;
          font-size: 1.2rem;
          color: #fff;
        }
        > p {
          margin: 0;
          font-size: 0.875rem;
        }
      }
      &:hover {
        &::after {
          opacity: 1;
        }
        > span {
          transform: translateY(0%);
        }
      }
    }
  }
}
