// =======================================
// Vars
// =======================================

:root {
  --bg-color: #ffffff;
  --bg-secondary-color: #e7edf5;
  --color-primary: #3454d0;
  --color-primary-darker: #2943a6;
  --color-secondary: #28323c;
  --color-text: #616786;
  --color-headline: #28304e;
  --color-lightGrey: #d2d6dd;
  --color-grey: #747681;
  --color-darkGrey: #3f4144;
  --color-error: #d43939;
  --color-success: #28bd14;
  --grid-gap: 30px;
  --border-radius: 3px;
  --font-size: 1.6rem;
  --font-family: "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  --font-family-headline: "PT Serif", "Segoe UI", "Helvetica Neue", sans-serif;
}
