// =======================================
// Resets
// =======================================

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, Sans-serif;
  color: #000;
  background: #fff;
}

html,
body,
blockquote,
form {
  margin: 0;
  padding: 0;
}

blockquote,
code,
embed,
iframe,
img,
input,
object,
pre,
table,
td,
textarea,
video {
  max-width: 100%;
  height: auto;
}

a img,
form,
fieldset {
  border: none;
}

input,
button,
select {
  vertical-align: middle;
}

textarea {
  vertical-align: top;
}

abbr {
  cursor: help;
  border-bottom: 1px dotted #bbb;
}

blockquote p,
button,
input,
li h1,
li h2,
li h3,
li h4,
li h5,
li h6,
li li,
li p,
select,
td p,
th p {
  font-size: 100%;
}
