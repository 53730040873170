#contact-sub {
  position: relative;
  .container {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    @media (min-width: 992px) {
      grid-gap: 80px;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }
    .text-box {
      order: 1;
      @media (min-width: 992px) {
        order: 1;
      }
      // form {
      //   display: grid;
      //   margin-top: 30px;
      //   grid-template-columns: repeat(2, minmax(0, 1fr));
      //   grid-gap: 20px;
      //   .full-width {
      //     grid-column: 1 / -1;
      //   }
      //   .btn {
      //     width: 100%;
      //   }
      // }
    }

    .info-box {
      border-radius: var(--border-radius);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: var(--color-secondary);
      order: 3;
      @media (min-width: 992px) {
        order: 2;
      }
      .upper {
        background: var(--color-secondary);
        padding: 50px;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: center;
        h3 {
          font-size: 19px;
          color: #fff;
          margin: 40px 0 10px 0;
          &:first-child {
            margin-top: 0;
          }
        }
        p {
          margin: 0;
          color: #fff;
          > a {
            color: #fff;
          }
        }
      }
      .lower {
        background: #1c252e;
        padding: 50px;
        p {
          @extend .lead;
          color: #fff;
        }
        .telefon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #fff;
          font-size: 24px;
          svg {
            height: 50px;
            width: 50px;
            padding: 10px;
            border: 2px solid rgba(255, 255, 255, 0.4);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-right: 15px;
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .map-box {
      grid-column: 1 / -1;
      order: 2;
      @media (min-width: 992px) {
        order: 3;
      }
      iframe {
        height: 500px;
      }
    }
  }
}
