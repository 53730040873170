#news-start {
  position: relative;
  margin: 2rem 0 3rem;
  @media (min-width: 992px) {
    margin: 4rem 0 6rem;
  }
  .container {
    display: grid;
    align-items: center;
    color: var(--color-text);
    border-radius: var(--border-radius);
    background: var(--bg-secondary-color);
    @media (min-width: 768px) {
      grid-gap: 80px;
      grid-template-columns: 280px auto;
    }
    .text-box {
      grid-column: 2 / -1;
      grid-row: 1;
      padding: 30px;
      @media (min-width: 768px) {
        padding: 30px 30px 30px 0;
      }
    }
    .image-box {
      margin: -30px 0 0 30px;
      width: auto;
      background-image: url(../images/job.png);
      height: calc(100% + 30px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
  }
}
