#footer {
  position: relative;
  margin: 6rem 0 0;
  overflow: hidden;
  .footer-top {
    position: relative;
    background: var(--color-secondary);
    padding: 5rem 0;
    .container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 30px;
      @media (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      .footer-logo {
        max-width: 180px;
        grid-column: 1/-1;
        @media (min-width: 992px) {
          grid-column: auto;
        }
      }
      .boxes {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          > li {
            margin-bottom: 10px;
            > a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    position: relative;
    background: #1c252e;
    padding: 20px 0;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      > span,
      > a {
        color: rgba(#fff, 0.5);
        font-size: 0.85rem;
      }
    }
  }
}
