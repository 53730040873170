@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

// =======================================
// Import Modules
// =======================================

@import "_utilities/vars";

@import "_reset/resets";
@import "_reset/normalize";

@import "_utilities/base";
@import "_utilities/form";
@import "_utilities/button";

@import "_other/accordion";
@import "_other/banner";

@import "_layout/header";
@import "_layout/hero";
@import "_layout/index";
@import "_layout/projects";
@import "_layout/aboutus";
@import "_layout/service";
@import "_layout/career";
@import "_layout/contact";
@import "_layout/footer";
