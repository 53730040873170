// =======================================
// Forms
// =======================================

fieldset {
  padding: 0.5rem 2rem;
}

legend {
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.1rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]),
select,
textarea,
textarea[type="text"] {
  font-family: inherit;
  padding: 0.8rem 1rem;
  border-radius: 4px;
  border: 1px solid var(--color-lightGrey);
  font-size: 1em;
  transition: all 0.2s ease;
  display: block;
  width: 100%;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]):not(:disabled):hover,
select:hover,
textarea:hover,
textarea[type="text"]:hover {
  border-color: var(--color-grey);
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]):focus,
select:focus,
textarea:focus,
textarea[type="text"]:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 1px var(--color-primary);
}

input.error:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]),
textarea.error {
  border-color: var(--color-error);
}

input.success:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]),
textarea.success {
  border-color: var(--color-success);
}

select {
  -webkit-appearance: none;
  background: #f3f3f6 no-repeat 100%;
  background-size: 1ex;
  background-origin: content-box;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='60' height='40' fill='%23555'><polygon points='0,0 60,0 30,40'/></svg>");
}

[type="checkbox"],
[type="radio"] {
  width: 1.6rem;
  height: 1.6rem;
}
