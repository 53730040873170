// =======================================
// Buttons
// =======================================

@mixin btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: white;
  text-decoration: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  height: 50px;
  cursor: pointer;
  padding: 0 30px;
  transition: background-color 0.2s ease;
  &:hover,
  &:focus {
    background-color: var(--color-primary-darker);
  }
  &:active {
    transform: scale(0.98);
  }
}

.btn,
[type="button"],
[type="reset"],
[type="submit"],
button {
  @include btn;
}

input:disabled,
button:disabled,
input:disabled:hover,
button:disabled:hover {
  opacity: 0.4;
  cursor: not-allowed;
}

::placeholder {
  color: #bdbfc4;
}
