// =======================================
// Base
// =======================================

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: var(--bg-color);
  line-height: 1.7;
  letter-spacing: 0.3px;
  color: var(--color-text);
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  letter-spacing: 0;
  font-weight: 700;
  margin: 0 0 1rem 0;
  line-height: 1.3;
  color: var(--color-headline);
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: var(--font-family-headline);
}

h1,
.h1 {
  font-size: 2.2rem;
  @media (min-width: 576px) {
    font-size: 2.7rem;
  }
  @media (min-width: 992px) {
    font-size: 3.2rem;
  }
}

h2,
.h2 {
  font-size: 1.7rem;
  @media (min-width: 576px) {
    font-size: 2.1rem;
  }
  @media (min-width: 992px) {
    font-size: 2.4rem;
  }
}

h3,
.h3 {
  font-size: 1.3rem;
  @media (min-width: 576px) {
    font-size: 1.7rem;
  }
  @media (min-width: 992px) {
    font-size: 2.1rem;
  }
}

h4,
.h4 {
  font-size: 1.1rem;
  @media (min-width: 576px) {
    font-size: 1.5rem;
  }
  @media (min-width: 992px) {
    font-size: 1.7rem;
  }
}

h5,
.h5 {
  font-size: 1.2rem;
}

h6,
.h6 {
  font-size: 0.85rem;
}

.lead {
  font-size: 1.125rem;
  @media (min-width: 992px) {
    font-size: 1.2rem;
  }
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover:not(.btn) {
  color: var(--color-primary-darker);
}

button {
  font-family: inherit;
  border: none;
}

p {
  margin-top: 0;
}

blockquote {
  background-color: var(--bg-secondary-color);
  padding: 1.5rem 2rem;
  border-left: 3px solid var(--color-lightGrey);
}

dl dt {
  font-weight: bold;
}

hr {
  border: none;
  background-color: var(--color-lightGrey);
  height: 1px;
  margin: 1rem 0;
}

table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

table.striped tr:nth-of-type(2n) {
  background-color: var(--bg-secondary-color);
}

td,
th {
  vertical-align: middle;
  padding: 1.2rem 0.4rem;
}

thead {
  border-bottom: 2px solid var(--color-lightGrey);
}

tfoot {
  border-top: 2px solid var(--color-lightGrey);
}

code,
kbd {
  padding: 0 0.4rem;
  font-size: 90%;
  white-space: pre-wrap;
  border-radius: 4px;
  padding: 0.2em 0.4em;
  background-color: var(--bg-secondary-color);
  color: var(--color-error);
}

pre {
  background-color: var(--bg-secondary-color);
  font-size: 1em;
  padding: 1rem;
  overflow-x: auto;
}

pre code {
  background: none;
  padding: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

img {
  max-width: 100%;
  display: block;
}

fieldset {
  border: 1px solid var(--color-lightGrey);
}

iframe {
  border: 0;
}

.container {
  height: 100%;
  margin: 0 20px;

  @media (min-width: 576px) {
    max-width: 540px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1260px) {
    max-width: 1200px;
  }
}

.show-xs {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.main-content {
  margin: 3rem 0;
  position: relative;
  @media (min-width: 992px) {
    margin: 6rem 0;
  }
}

li::marker {
  color: var(--color-primary);
}

.glightbox-body {
  .ginner-container {
    .gslide-image {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
    .gslide-description {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      .gdesc-inner {
        > h4 {
          @extend .h3;
          font-size: 1.2rem;
        }
      }
    }
  }
}
